export function uuid (): string {
  const s: any[] = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 10; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

  const uuid = s.join('');
  return uuid;
}
