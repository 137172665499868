import _ from 'lodash';
// @ts-ignore
import dayjs from 'dayjs';

export function isNewVersion (appKey: string) {
  return !['5693d0caf3722bc3de550529', 'test_v1'].includes(appKey);
}

export function getSubjectName (subject: string): string {
  const map: any = { math: '数理科', chinese: '中英', 'en-math': '英文数理' };
  return map[subject];
}

export function formatDate (v: number | Date | string, format = 'YYYY-MM-DD HH:mm') {
  if (!v) { return '--'; }
  const date = new Date(typeof v === 'number' && `${v}`.length <= 11 ? v * 1000 : v);
  if (isNaN(date.getTime())) { return '--'; }
  return dayjs(date).format(format);
}

export function smartnumber (v: number, decimals = 1) {
  if (isNaN(v)) { return '--'; }
  const d = Math.pow(10, decimals);
  return Math.round(v * d) / d;
}

export function * enumerate<T> (iterable: Iterable<T>): IterableIterator<[T, number]> {
  let i = 0;
  for (const item of iterable) {
    yield [item, i];
    i += 1;
  }
}

// 倒序遍历
export function * backIterate<T> (list: ArrayLike<T>) {
  for (let i = list.length - 1; i >= 0; i -= 1) {
    yield list[i];
  }
}
// 去除筛选条件的空值
export const removeEmpty = (obj: any) => {
  const c = _.cloneDeep(obj);
  for (const i in c) {
    if (c[i] === '' || c[i] === null) {
      delete c[i];
    }
  }
  return c;
};
