
import { Component, Vue } from 'vue-property-decorator';

import OrderList from './components/OrderList.vue';

@Component({
  components: {
    OrderList
  }
})
export default class MyOrders extends Vue {
}
