
// 外部依赖
import * as _ from 'lodash';
import { FormatAddOne } from '@/utils/Time';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
// @ts-ignore
import { saveAs } from 'file-saver';
// api
import {
  getOrder,
  getVerifyDeliver,
  rerunWord2FbdOrder,
  rerunStu2TeaOrder,
  rerunOrderStatus,
  getDraftingImageList,
  getDraftingProjectInfo,
  editDraftingProject,
  creatGalleryProject,
  reRunWord2PPTTask, getDeductPoint, reRunJSON2PPTTask
} from '@/api/Allservices';
import { getSubject, getStage, getGrade } from '@/api/education';
// import { editOrder } from '@/api/placeOrder';
// 工具函数
import { removeEmpty } from '@/utils/helper';
// types
import { ElTable } from 'element-ui/types/table';
import { IOrderObject } from '@/types/orderDetail';
import { IConfig } from '@/types/placeOrderTypes';
import { isArray } from '@/utils/validate';
import { IDeliver } from '@/types/service';
// 定义的服务数据
import { IService, serviceList } from '@/business/ServiceContent';
import { IDefaultConfigData } from '@/business/DefaultConfigData';
import ConfigForm from '../../placeOrder/components/ConfigForm.vue';
import DraftingList from './DraftingList.vue';
import { getOrgInfoByOId, own } from '@/api/login';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { RepeatedAsyncExecutor } from '@/utils/RepeatedAsyncExecutor';

interface IConditions {
  page: number;
  limit: number;
  status: string | number;
  status_str: string;
  alias: string;
  service_content: string | number;
  hidden_close: boolean;
  subject?: string;
  sort?: string;
}
@Component({
  components: {
    ConfigForm,
    DraftingList
  }
})
export default class OrderList extends Vue {
  @Action('setEduInfo') setEduinfo: any;
  @Action('setUser') setUser: any;
  @Getter('eduInfo') eduInfo: any;
  @Getter('user') user: any;

  getErrorInfo (errorInfo: string) {
    if (errorInfo.includes('答案册试题册数量差异过大')) {
      return errorInfo.match(/答案册试题册数量差异过大，请检查（试题册：\d+，答案册：\d+）/gim)?.[0] || '答案册试题册数量差异过大，请检查';
    }
    if (errorInfo.includes('存在不符合规范的题号')) {
      return '存在不符合规范的题号，请检查是否存在连续超过200个的题号';
    }
    if (errorInfo.includes('gb18030')) {
      return '文件存在无法解析的字符，请检查是否文件存在加密或者使用了非gb18030字符集的字符';
    }
    return '文件处理异常，请在微信服务群中进行咨询。';
  }

  get subjects () {
    if (this.eduInfo) {
      return this.eduInfo.subject || [];
    }
    return [];
  }
  // 学科去重
  get subjectsList () {
    if (this.subjects) {
      const res: any[] = [];
      this.subjects.map((cur: any) => {
        if (!res.find(item => item.subject === cur.subject)) {
          res.push(cur);
        }
      });
      return res || [];
    }
    return [];
  }

  get stages () {
    if (this.eduInfo) {
      return this.eduInfo.stage || [];
    }
    return [];
  }

  get isDebug () {
    const { query } = this.$route;
    return Object.hasOwnProperty.call(query, 'debug');
  }

  get orgId () {
    return this.$store.state.user?.orgs[0]?.orgId || 0;
  }

  get userOptions () {
    return this.$store.state.user?.orgs[0] || {};
  }

  get hasExpiredTip () {
    return this.deliver && this.OTHER.length && !this.OTHER?.[0]?.expiration;
  }

  v1Icon = require('@/assets/common/PPTIcon.svg');
  tableLoading = false
  entries: number[] = [];
  async activated () {
    window.addEventListener('resize', this.resize);

    let entries = [0, 2, 3, 5, 9];
    // const OrgInfo = await getOrgInfoByOId({ orgId: this.orgId });
    let OrgInfo;
    if (this.$store.state.OrgInfo) {
      OrgInfo = this.$store.state.OrgInfo;
    } else {
      OrgInfo = await getOrgInfoByOId({ orgId: this.orgId });
    }

    if (OrgInfo.entries && OrgInfo.entries.length) entries = OrgInfo.entries.split(',').map((item: string) => Number(item));
    this.entries = entries;

    await this.getFilterList();
    this.listExecutor.restart(false);

    await this.getEduInfo();
    this.$nextTick(() => {
      (this.$refs.dataTable as ElTable)?.doLayout();
    });
  }
  orderList: IOrderObject[] = [];
  count = 0;
  async getEduInfo () {
    const storage = window.localStorage;
    try {
      const localSubject = JSON.parse(storage.getItem('subject') as string);
      const localStage = JSON.parse(storage.getItem('stage') as string);
      const localGrade = JSON.parse(storage.getItem('grade') as string);
      if (isArray(localSubject) && isArray(localStage) && isArray(localGrade) && localSubject.length && localStage.length && localGrade.length) {
        this.setEduinfo({
          subject: localSubject,
          grade: localGrade,
          stage: localStage
        });
        return;
      } else {
        throw new Error('get eduInfo');
      }
    } catch (error) {
      const [subject, stage, grade] = await Promise.all([getSubject({}), getStage({}), getGrade({})]);
      if (!storage.getItem('subject') || storage.getItem('subject') === 'null' || storage.getItem('subject') === 'undefined' || !storage.getItem('subject')?.length) {
        storage.setItem('subject', JSON.stringify(subject));
      }
      if (!storage.getItem('stage') || storage.getItem('stage') === 'null' || storage.getItem('stage') === 'undefined' || !storage.getItem('stage')?.length) {
        storage.setItem('stage', JSON.stringify(stage));
      }
      if (!storage.getItem('grade') || storage.getItem('grade') === 'null' || storage.getItem('grade') === 'undefined' || !storage.getItem('grade')?.length) {
        storage.setItem('grade', JSON.stringify(grade));
      }
      this.setEduinfo({
        subject,
        grade,
        stage
      });
    }
  }
  async getOwnOrder () {
    if (this.$store.state.organization) {
      const { id } = this.$store.state.organization;
      const obj = removeEmpty(this.condistions);
      const res = await getOrder({
        customer_id: id,
        ...obj,
        hidden_company: true,
      });
      this.orderList = res.tickets;
      this.count = res.count;
    }
  }
  // 服务类型筛选
  optionsOrigin = [
    {
      value: '0,2,3,4,5,9,10,11,12,13,14,15,16,17,18,19',
      label: '全部类型'
    },
    {
      value: 0,
      label: '课件制作'
    },
    {
      value: 2,
      label: '图片转 WORD'
    },
    {
      value: 3,
      label: '方正转 WORD'
    },
    {
      value: 4,
      label: '图片转方正',
      isDebug: true,
    },
    {
      value: 5,
      label: '内容数字化'
    },
    {
      value: 9,
      label: 'PDF 转 WORD',
      isDebug: true,
    },
    {
      value: 10,
      label: '学转教',
      isDebug: true,
    },
    {
      value: 11,
      label: 'WORD 转方正',
      isDebug: true,
    },
    {
      value: 12,
      label: '彩色图片改单黑',
      isDebug: true,
    },
    {
      value: 13,
      label: '单黑图片改专色',
      isDebug: true,
    },
    {
      value: 14,
      label: '教用辅助线改专色',
      isDebug: true,
    },
    {
      value: 15,
      label: '图片提高清晰度V1',
      isDebug: true,
    },
    {
      value: 16,
      label: 'WORD 转方正',
      isDebug: true,
    },
    {
      value: 17,
      label: '图片提高清晰度V2',
      isDebug: true,
    },
    {
      value: 18,
      label: 'WORD 转 PPT',
      isDebug: false,
    },
    {
      value: 19,
      label: 'JSON 转 PPT',
      isDebug: false,
    },
    {
      value: 21,
      label: 'HTML 转 WORD',
      isDebug: false,
    },
  ]
  get options () {
    if (this.isDebug) return this.optionsOrigin;
    else return this.optionsOrigin.filter(item => !item.isDebug || this.entries.includes(item.value));
  }
  // 状态筛选
  selectStatus = ''
  statusOptions = [
    {
      value: '',
      label: '全部状态'
    },
    {
      value: 0,
      label: '未提交'
    },
    // {
    //   value: 1,
    //   label: '已关闭'
    // },
    {
      value: 2,
      label: '审核中'
    },
    {
      value: 3,
      label: '审核未通过'
    },
    {
      value: 4,
      label: '制作中'
    },
    {
      value: 8,
      label: '已完成'
    },
    {
      value: 9,
      label: '已失败'
    },
  ]
  oldCondistions: any = null
  // 处理状态筛选
  handleStatusSelect (selectStatus: number) {
    if (selectStatus === 4) {
      this.condistions.status_str = 'make';
      this.condistions.status = '';
    } else {
      this.condistions.status = selectStatus;
      this.condistions.status_str = '';
    }
  }
  // 分页、筛选相关
  condistions: IConditions = {
    page: 1,
    limit: 15,
    status: '',
    status_str: '',
    alias: '',
    service_content: '0,2,3,4,5,9,10,11,12,13,14,15,16,17,18,19',
    hidden_close: true,
    subject: '',
    sort: 'create_time'
  }
  @Watch('condistions', { deep: true })
  async list (newVal: any) {
    const oldValue = this.oldCondistions;
    this.oldCondistions = _.cloneDeep(newVal);
    if (newVal.alias === oldValue?.alias) {
      if (newVal.service_content !== oldValue?.service_content ||
        newVal.status !== oldValue?.status ||
        newVal.subject !== oldValue?.subject ||
        newVal.limit !== oldValue?.limit
      ) {
        this.condistions.page = 1;
      }
      console.log('请求');
      await this.debounceGetList();
    }
  }

  // condistions变化时需要把page重置为1
  // @Watch('condistions.alias')
  // @Watch('condistions.service_content')
  // @Watch('condistions.status')
  // @Watch('condistions.limit')
  // @Watch('condistions.subject')
  async resetPage () {
    await this.debounceGetList();
    this.condistions.page = 1;
  }

  async getList () {
    await this.getFilterList();
    this.listExecutor.restart(false);
  }

  listExecutor = new RepeatedAsyncExecutor<boolean>(this.getFilterList, 5000);
  debounceGetList = debounce(this.getList, 500, { leading: true });

  async getFilterList (loading = true) {
    if (this.$store.state.organization) {
      if (loading) { this.tableLoading = true; }
      const { id } = this.$store.state.organization;
      const obj = removeEmpty(this.condistions);
      // 兼容查询工单tick-id
      const reg = /^[a-z0-9]{24}$/;
      if (obj.alias && reg.test(obj.alias.trim())) {
        obj.ticket_id = obj.alias.trim();
        delete obj.alias;
      }
      const res = await getOrder({
        customer_id: id,
        ...obj,
        hidden_company: true,
      });
      this.orderList = res.tickets;
      this.count = res.count;

      this.$nextTick(() => {
        (this.$refs.dataTable as ElTable)?.doLayout();
      });
      setTimeout(() => {
        this.tableLoading = false;
      }, 300);
    }
  }

  handleResize () {
    this.$nextTick(() => {
      (this.$refs.dataTable as ElTable)?.doLayout();
    });
  }
  resize = _.debounce(this.handleResize, 100)

  // 展开服务类型
  service = serviceList.reduce((pre, next) => {
    return pre.concat(next.list);
  }, [] as IService[])
  // 继续下单
  proceedOrder (row: IOrderObject) {
    const id = row.ticket_id;
    const service = this.service.find(item => item.service_content === row.service_content)!;
    window.sessionStorage.setItem('service_type', service.mapKey);
    window.sessionStorage.setItem('service_name', service.name);
    // this.$router.push(`/create?id=${id}`);
    this.$router.push({
      // tips: path 和 params 不能同时用
      // path: '/create',
      name: 'create',
      query: {
        id
      },
      params: {
        serviceType: service.mapKey,
        name: service.name
      },
    });
  }
  // 订单详情
  detailVisible = false
  detailOrder: IOrderObject | null = null
  openDetail (row: IOrderObject) {
    this.detailOrder = row;
    this.detailVisible = true;
  }
  clearDetail () {
    this.detailOrder = null;
  }
  // 下载结果
  deliveryVisible = false
  deliverLoading = false
  deliverOrder: IOrderObject | null = null
  deductPointTypeMap: { [key: number]: string } = {
    16: 'word',
    18: 'ppt',
    19: 'json'
  }

  async openDelivery (row: IOrderObject) {
    if ([16, 18, 19].includes((row.service_content as number)) && !this.isDebug) {
      let rowCount = row?.material?.oss_word_files?.[0].ppt_page_count || 0;
      if (row.service_content === 19) {
        rowCount = row!.xdoc_planning?.[0].ppt_page_count || 0;
      } else if (row.service_content === 16) {
        rowCount = row?.material?.oss_word_files?.[0].page_count || 0;
      }
      if (rowCount <= 0) {
        if (!this.isDebug) {
          return this.$message.error('PPT页数生成失败,请联系管理员!');
        }
      } else {
        if (!row.is_pay) {
          if ((+this.user?.orgs[0].wordPoints || 0) - rowCount >= 0) {
            this.$confirm(
              `您的账户剩余下载页数为 ${this.user?.orgs[0].wordPoints ||
              0}，扣除当前下载页数 ${rowCount}，扣除完剩余 ${(+this.user?.orgs[0].wordPoints || 0) -
              rowCount} 页，是否确认下载`,
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }
            ).then(async () => {
              this.deliverOrder = row;
              this.deliveryVisible = true;
              this.deliverLoading = true;
              this.deliver = await this.getDeliver(row);
              this.deliverLoading = false;
              if (this.orderList.find(v => v.ticket_id === row.ticket_id)) {
                this.orderList.find(v => v.ticket_id === row.ticket_id)!.is_pay = true;
              }
              if (this.user?.orgs[0].orgId !== 2) {
                await getDeductPoint({ ticket_id: row.ticket_id, point_type: this.deductPointTypeMap[row.service_content || 18] });
                await this.getUserInfo();
              }
            });
          } else {
            await this.$confirm('您的账号剩余页数不足，请联系管理人员！', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              showCancelButton: false
            });
          }
        } else {
          this.deliverOrder = row;
          this.deliveryVisible = true;
          this.deliverLoading = true;
          this.deliver = await this.getDeliver(row);
          this.deliverLoading = false;
        }
      }
    } else {
      this.deliverOrder = row;
      this.deliveryVisible = true;
      this.deliverLoading = true;
      this.deliver = await this.getDeliver(row);
      this.deliverLoading = false;
    }
  }

  async downloadDelivery (row: IOrderObject, type = 'ppt') {
    if (row.service_content === 16 && !this.isDebug) {
      const rowCount = row!.material!.oss_word_files![0].page_count || 0;
      if (rowCount <= 0) {
        if (!this.isDebug) {
          return this.$message.error('PPT页数生成失败,请联系管理员!');
        }
      } else {
        if (!row.is_pay) {
          if ((+this.user?.orgs[0].wordPoints || 0) - rowCount >= 0) {
            this.$confirm(
              `您的账户剩余下载页数为 ${this.user?.orgs[0].wordPoints ||
              0}，扣除当前下载页数 ${rowCount}，扣除完剩余 ${(+this.user?.orgs[0].wordPoints || 0) -
              rowCount} 页，是否确认下载`,
              '提示',
              {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }
            ).then(async () => {
              const res = await this.getDeliver(row);
              const delivery = res.WORD2FBD?.[res.WORD2FBD.length - 1]?.word2fbd?.[0] || res.WORD2FBD?.[res.WORD2FBD?.length - 1];
              if (delivery && delivery.adjust_url) {
                await this.download(row, delivery.adjust_url, delivery.task_name + '.zip');
              } else if (delivery && delivery.zip_url) {
                await this.download(row, delivery.zip_url, delivery.task_name + '.zip');
              } else {
                this.$message.error('下载失败！请联系管理员。');
              }
              if (this.orderList.find(v => v.ticket_id === row.ticket_id)) {
                this.orderList.find(v => v.ticket_id === row.ticket_id)!.is_pay = true;
              }
              if (this.user?.orgs[0].orgId !== 2) {
                await getDeductPoint({ ticket_id: row.ticket_id, point_type: row.service_content === 18 ? 'ppt' : 'word' });
                await this.getUserInfo();
              }
            });
          } else {
            await this.$confirm('您的账号剩余页数不足，请联系管理人员！', '提示', {
              confirmButtonText: '确定',
              type: 'warning',
              showCancelButton: false
            });
          }
        } else {
          const res = await this.getDeliver(row);
          const delivery = res.WORD2FBD?.[res.WORD2FBD.length - 1]?.word2fbd?.[0] || res.WORD2FBD?.[res.WORD2FBD?.length - 1];
          if (delivery && delivery.adjust_url) {
            await this.download(row, delivery.adjust_url, delivery.task_name + '.zip');
          } else if (delivery && delivery.zip_url) {
            await this.download(row, delivery.zip_url, delivery.task_name + '.zip');
          } else {
            this.$message.error('下载失败！请联系管理员。');
          }
        }
      }
    } else {
      const res = await this.getDeliver(row);
      // const delivery = res.WORD2FBD[res.WORD2FBD.length - 1].word2fbd[0];
      const delivery = res.WORD2FBD?.[res.WORD2FBD.length - 1]?.word2fbd?.[0] || res.WORD2FBD?.[res.WORD2FBD?.length - 1];
      if (delivery && delivery.adjust_url) {
        await this.download(row, delivery.adjust_url, delivery.task_name + '.zip');
      } else if (delivery && delivery.zip_url) {
        await this.download(row, delivery.zip_url, delivery.task_name + '.zip');
      } else {
        this.$message.error('下载失败！请联系管理员。');
      }
    }
  }

  async getUserInfo () {
    const res = await own();
    await this.setUser(res);
  }

  async realDownLoad (row: any, type = 'ppt') {

  }

  deliver: IDeliver | null = null
  async getDeliver (row: IOrderObject) {
    try {
      const services: [number, string][] = [[9, 'WORD'], [10, 'STU2TEA'], [11, 'WORD2FBD'], [12, 'TOGRAY'], [13, 'TOCOLOR'], [14, 'TODIFF'], [15, 'TOSR'], [16, 'WORD2FBD'], [17, 'TOSR2'], [18, 'WORD2PPT'], [19, 'JSON2PPT']];
      let process_name = services.find(s => s[0] === row.service_content)?.[1];
      if (process_name === 'WORD') {
        process_name = 'OTHER';
      }
      const res = await getVerifyDeliver({
        ticket_id: row.ticket_id,
        process_name
      });
      return res;
    } catch (error) {
      // this.$message.error('请求结果列表错误');
      // this.deliveryVisible = false;
      return null;
    }
  }

  get OTHER () {
    const { deliver } = this;

    if (deliver && Array.isArray(deliver.OTHER) && deliver.OTHER.length) {
      return deliver.OTHER;
    }
    return [];
  }

  get STU2TEA () {
    // const { deliver } = this;
    // if (deliver && Array.isArray(deliver.STU2TEA) && deliver.STU2TEA.length) {
    //   return deliver.STU2TEA;
    // }
    // return [];
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.STU2TEA) && deliver.STU2TEA.length) {
      const len = deliver.STU2TEA.length - 1;
      return (deliver.STU2TEA?.[len] as any)?.stu2tea || deliver.STU2TEA;
    }
    return [];
  }

  get WORD2FBD () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.WORD2FBD) && deliver.WORD2FBD.length) {
      const len = deliver.WORD2FBD.length - 1;
      return (deliver.WORD2FBD?.[len] as any)?.word2fbd || deliver.WORD2FBD;
    }
    return [];
  }

  get TOGRAY () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.TOGRAY) && deliver.TOGRAY.length) {
      return deliver.TOGRAY;
    }
    return [];
  }

  get TOCOLOR () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.TOCOLOR) && deliver.TOCOLOR.length) {
      return deliver.TOCOLOR;
    }
    return [];
  }

  get TODIFF () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.TODIFF) && deliver.TODIFF.length) {
      return deliver.TODIFF;
    }
    return [];
  }

  get TOSR () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.TOSR) && deliver.TOSR.length) {
      return deliver.TOSR;
    }
    return [];
  }

  get TOSR2 () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.TOSR2) && deliver.TOSR2.length) {
      return deliver.TOSR2;
    }
    return [];
  }

  get WORD2PPT () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.WORD2PPT) && deliver.WORD2PPT.length) {
      const len = deliver.WORD2PPT.length - 1;
      return (deliver.WORD2PPT?.[len] as any)?.word2ppt || deliver.WORD2PPT;
    }
    return [];
  }

  get JSON2PPT () {
    const { deliver } = this;
    if (deliver && Array.isArray(deliver.JSON2PPT) && deliver.JSON2PPT.length) {
      const len = deliver.JSON2PPT.length - 1;
      return (deliver.JSON2PPT?.[len] as any)?.JSON2PPT || deliver.JSON2PPT;
    }
    return [];
  }

  clearDelivery () {
    this.deliverOrder = null;
    this.deliver = null;
  }
  // 复制到剪贴板
  copyToClipboard (text: string) {
    const textArea = document.createElement('textarea');
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';
    textArea.value = `${text}?time=${dayjs().valueOf()}`;
    document.body.appendChild(textArea);
    textArea.select();
    try {
      document.execCommand('copy');
      this.$message.success('复制成功');
    } catch (err) {
      this.$message.error('复制失败,该浏览器不支持点击复制到剪贴板');
    }
    document.body.removeChild(textArea);
  }
  // 直接下载
  async download (row: any, url: string, name?: string) {
    try {
      if (name) saveAs(`${url}?time=${dayjs().valueOf()}`, name);
      else this.downloadOrigin(`${url}?time=${dayjs().valueOf()}`);
    } catch (e) {
      this.$message.error('下载异常，请联系管理人员！');
    }
  }
  // 浏览器直接直接下载
  downloadOrigin (url: string) {
    const a = document.createElement('a');
    a.href = url;
    a.click();
  }
  // 销毁前
  beforeDestroy () {
    window.removeEventListener('resize', this.resize);
  }

  deactivated () {
    this.listExecutor.stop();
    window.removeEventListener('resize', this.resize);
  }

  configVisible = false
  projectConfig: IConfig | null = null

  openConfig (config: IConfig) {
    this.projectConfig = config;
    this.configVisible = true;
  }

  clearConfig () {
    this.projectConfig = null;
  }

  async saveConfigHandle (config: IDefaultConfigData) {
    if (this.projectConfig) {
      this.projectConfig.stu2tea_config = config;
    }
    const formData: any = { ticket_id: this.detailOrder?.ticket_id, ...this.detailOrder };
    // await editOrder(formData);
    this.projectConfig = null;
    this.configVisible = false;
  }

  async rerunOrderHandle (row: IOrderObject) {
    try {
      if (row.service_content === 10) {
        await rerunStu2TeaOrder({
          ticket_id: row.ticket_id,
          run_type: 'rerun'
        });
      } else {
        await rerunWord2FbdOrder({
          ticket_id: row.ticket_id,
          run_type: 'rerun'
        });
      }
      await rerunOrderStatus({
        ticket_id: row.ticket_id,
        status: row.status
      });
      this.$message.success('重跑成功');
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * word2ppt 重跑
   * @param row
   */
  async reRunW2P (row: IOrderObject) {
    try {
      await Promise.all([reRunWord2PPTTask({ ticket_id: row.ticket_id }),
        rerunOrderStatus({
          ticket_id: row.ticket_id
        })]);
      this.orderList = this.orderList.map((v: IOrderObject) => {
        if (v.ticket_id === row.ticket_id) {
          v.status = 5;
        }
        return v;
      });
      this.$notify({
        message: '重跑成功',
        title: '成功',
        type: 'success'
      });
    } catch (e) {
      this.$notify({
        message: '重跑失败',
        title: '失败',
        type: 'error'
      });
    }
  }

  /**
   * json2ppt 重跑
   * @param row
   */
  async reRunJ2P (row: IOrderObject) {
    try {
      await Promise.all([
        reRunJSON2PPTTask({ ticket_ids: [row.ticket_id] }),
        rerunOrderStatus({ ticket_id: row.ticket_id })]);
      this.orderList = this.orderList.map((v: IOrderObject) => {
        if (v.ticket_id === row.ticket_id) {
          v.status = 5;
        }
        return v;
      });
      this.$notify({
        message: '重跑成功',
        title: '成功',
        type: 'success'
      });
    } catch (e) {
      this.$notify({
        message: '重跑失败',
        title: '失败',
        type: 'error'
      });
    }
  }

  editRerunOrderHandle (row: IOrderObject) {
    if (row.service_content === 10) {
      window.sessionStorage.setItem('service_type', 'studentToTeacher');
      window.sessionStorage.setItem('service_name', '学用转教用');
    } else if (row.service_content === 11) {
      window.sessionStorage.setItem('service_type', 'wordToFbd');
      window.sessionStorage.setItem('service_name', 'Word转方正书版');
    } else {
      window.sessionStorage.setItem('service_type', 'wordToFbd_auto');
      window.sessionStorage.setItem('service_name', 'Word转方正书版-自动');
    }
    // 跳转去对应的id create页面
    this.$router.push(`/create?id=${row.ticket_id}&fbd_config_visible=0&reEdit=true`);
  }

  draftingVisible = false;
  draftingData: {
    image_task_requirement?: string;
    image_task_sample?: [];
    image_list?: [];
  } = {};
  draftingOrder: IOrderObject | null = null;
  draftingProjectId = '';
  timer: any = null;

  async openDrafting (row: IOrderObject) {
    const project_info = await getDraftingProjectInfo({
      platform_id: row.ticket_id
    });
    this.draftingProjectId = project_info._id;
    this.draftingData.image_task_requirement = project_info.desc;
    this.draftingData.image_task_sample = project_info.sample_path;
    const image_list = await getDraftingImageList({
      platform_id: row.ticket_id
    });
    this.draftingData.image_list = image_list;
    if (image_list.length) {
      this.updateImageListLoop();
    }
    this.draftingOrder = row;
    this.draftingVisible = true;
  }

  updateImageListLoop () {
    if (this.timer) {
      return;
    }
    this.timer = setInterval(async () => {
      this.updateImageList();
    }, 10000);
  }

  async updateImageList () {
    const image_list = await getDraftingImageList({
      platform_id: this.draftingOrder?.ticket_id
    });
    this.draftingData.image_list = image_list;
  }

  clearDrafting () {
    this.draftingProjectId = '';
    this.draftingOrder = null;
    this.draftingData = {};
    clearInterval(this.timer);
    this.timer = null;
  }

  async CreatGalleryProjectHandle (desc: string, sample_path: [], image_list: []) {
    try {
      await editDraftingProject({
        project_id: this.draftingProjectId,
        desc,
        sample_path: sample_path.map((item: any) => {
          return {
            name: item.name,
            url: item.url
          };
        })
      });
      await creatGalleryProject({
        platform_id: this.draftingOrder?.ticket_id,
        image_ids: image_list.map((item: any) => item._id)
      });
      this.draftingVisible = false;
      this.$message.success('开始制图');
    } catch (error) {
      console.log(error);
    }
  }

  FormatAddOne (date: string) {
    return FormatAddOne(date);
  }

  /**
   * 分页触发的事件
   */
  changePage () {
    this.$nextTick(() => {
      if ((this.$refs.dataTable as any).bodyWrapper) { (this.$refs.dataTable as any).bodyWrapper.scrollTop = 0; }
    });
  }
}

