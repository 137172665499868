
import { Component, Vue, Prop } from 'vue-property-decorator';
import { selectFile } from '@/utils/FileApis';
import { uuid } from '@/utils/uuid';
// @ts-ignore
import CircleProgress from 'vue-circleprogressbar';
// @ts-ignore
import Oss from 'ali-oss';
import { getFileMd5, handleUrl } from '../../placeOrder/helper';
import { getOssInfo, getMaterialFile, creatMaterialFile, creatMaterialFileSymlink } from '@/api/placeOrder';
import { IFileList } from '@/types/placeOrderTypes';
@Component({
  components: {
    CircleProgress
  }
})
export default class DraftingList extends Vue {
  @Prop() data?: any;
  @Prop() order_id!: string;

  image_task_requirement = '';
  image_list = [
    {
      '_id': '63a035b8283315000ac291ff',
      'project_id': '63a03586283315000ac291ec',
      'node_id': 'e5887858de4748bbb67553f1347471d0',
      'task_id': '63a035a5283315000ac291f1',
      'status': 2,
      'filename': '23dc1',
      'ower_id': 3007797,
      'ower_name': '王世华',
      'reject': false,
      'available': true,
      'time_create': '2022-12-19 17:58:16',
      'time_modify': '2022-12-19 17:58:16',
      'img_id': 'b6b4b8f98',
      'original_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/b6b4b8f98/23dc1.jpg',
      'history': [],
      'new_tif_url': 'https://xdoc-stable.oss-cn-shanghai.aliyuncs.com/open/b9d0e662c647d3449a2b7513/image/4407619_c410665a/23dc1.eps?t=j1m63',
      'uploader_id': '',
      'uploader': '',
      'time_upload': '2022-12-19 17:58:16',
      'is_replaced': true,
      'new_jpg_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/23dc1.jpg?x-oss-process=image/resize,w_468&t=j1m63',
      'user_id': '',
      'username': 'gallery',
      'task_name': '第一篇 教材复习方案'
    },
    {
      '_id': '63a035b8283315000ac29200',
      'project_id': '63a03586283315000ac291ec',
      'node_id': 'c8c013b5114a4d3d8cbf6769549d6574',
      'task_id': '63a035a5283315000ac291f1',
      'status': -1,
      'filename': '加A2',
      'ower_id': 3007797,
      'ower_name': '王世华',
      'reject': false,
      'available': true,
      'time_create': '2022-12-19 17:58:16',
      'time_modify': '2022-12-19 17:58:16',
      'img_id': 'f0a6d2bb2',
      'original_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/f0a6d2bb2/加A2.jpg',
      'history': [],
      'new_tif_url': 'https://xdoc-stable.oss-cn-shanghai.aliyuncs.com/open/b9d0e662c647d3449a2b7513/image/4407619_c410665a/加A2.eps?t=yr5yi',
      'uploader_id': '',
      'uploader': '',
      'time_upload': '2022-12-19 17:58:16',
      'is_replaced': true,
      'new_jpg_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/加A2.jpg?x-oss-process=image/resize,w_296&t=yr5yi',
      'user_id': '',
      'username': 'gallery',
      'task_name': '第一篇 教材复习方案'
    },
    {
      '_id': '63a035b8283315000ac29201',
      'project_id': '63a03586283315000ac291ec',
      'node_id': '5235b06bd7634613af2dca84f401c49c',
      'task_id': '63a035a5283315000ac291f1',
      'status': 1,
      'filename': '23bw6',
      'ower_id': 3007797,
      'ower_name': '王世华',
      'reject': false,
      'available': true,
      'time_create': '2022-12-19 17:58:16',
      'time_modify': '2022-12-19 17:58:16',
      'img_id': '881365068',
      'original_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/881365068/23bw6.jpg',
      'history': [],
      'new_tif_url': 'https://xdoc-stable.oss-cn-shanghai.aliyuncs.com/open/b9d0e662c647d3449a2b7513/image/4407619_c410665a/23bw6.eps?t=3wgun',
      'uploader_id': '',
      'uploader': '',
      'time_upload': '2022-12-19 17:58:16',
      'is_replaced': true,
      'new_jpg_url': 'http://book-stable.oss-cn-shanghai.aliyuncs.com/img/63a03586283315000ac291ec/23bw6.jpg?x-oss-process=image/resize,w_424&t=3wgun',
      'user_id': '',
      'username': 'gallery',
      'task_name': '第一篇 教材复习方案'
    }
  ];
  files: IFileList[] = [];
  selectList = [];

  created () {
    if (this.data.image_task_requirement) this.image_task_requirement = this.data.image_task_requirement;
    if (this.data.image_list) this.image_list = this.data.image_list;
    if (this.data.image_task_sample) {
      this.files = this.data.image_task_sample.map((item: any) => {
        return {
          status: 'upload-success',
          name: item.name,
          url: item.url
        };
      });
    }
  }

  selectAFile () {
    selectFile({
      accept: '',
      multiple: true
    }, async (files) => {
      if (files.some(file => !/^((?!\\|\/|:|\*|\?|"|<|>|\||').)*$/g.test(file.name))) {
        this.$message.warning('存在非法字符\\ / : * ? " < > | \' ');
        return;
      }
      files.forEach((file) => {
        const uid = uuid();
        const item: IFileList = {
          id: uid,
          file: file,
          status: 'init',
          checkInfo: '',
          uploadInfo: '',
          progress: 0,
          name: file.name
        };
        this.files.push(item);
      });
    });
  }

  async uploadFile () {
    const files = this.files.filter(item => item.status !== 'upload-success');
    if (!files.length) {
      this.$message.warning('请先选择一个样例文件');
      return;
    }
    const auth = await getOssInfo({ ticket_id: this.order_id, path_alias: 'file' });
    const { access_key_id, access_key_secret_id, bucket, region, key } = auth;
    for (const item of files) {
      try {
        item.md5 = await getFileMd5(item.file as File) as string;
        item.status = 'uploadding';
        item.progress = 0;
        const material = await getMaterialFile({
          md5: item.md5!
        });
        const { path } = material;
        if (path) {
          const symlinkData = await creatMaterialFileSymlink({
            ticket_id: this.order_id,
            origin_path: path,
            name: item.name!,
            path_alias: 'courseware_sample'
          });
          item.url = `https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/${handleUrl(symlinkData.symlink_path)}`;
          item.progress = 100;
          item.status = 'upload-success';
          continue;
        }
        const client = new Oss({ region, accessKeyId: access_key_id, accessKeySecret: access_key_secret_id, bucket });
        await client.multipartUpload(`${key}/${item.md5}.zip`, item.zip, {
          parallel: 5,
          partSize: 1024 * 1024,
          timeout: 4000000,
          progress: function (p: number) {
            item.progress = +(p * 100).toFixed(2);
          }
        });
        await creatMaterialFile({
          md5: item.md5!,
          name: item.name!,
          extension: 'zip',
          path: `${key}/${item.md5}.zip`
        });
        const symlinkData = await creatMaterialFileSymlink({
          ticket_id: this.order_id,
          origin_path: `${key}/${item.md5}.zip`,
          name: item.name!,
          path_alias: 'courseware_sample'
        });
        item.url = `https://hexin-worksheet.oss-cn-shanghai.aliyuncs.com/${handleUrl(symlinkData.symlink_path)}`;
        item.status = 'upload-success';
      } catch (e) {
        item.status = 'upload-fail';
        item.uploadInfo = e as any;
        item.progress = 0;
      }
    }
  }

  sizeFilter (bytesize: number) {
    let i = 0;
    // 当bytesize 大于是1024字节时，开始循环，当循环到第4次时跳出；
    while (Math.abs(bytesize) >= 1024) {
      bytesize = bytesize / 1024;
      i++;
      if (i === 4) break;
    }
    // 将Bytes,KB,MB,GB,TB定义成一维数组；
    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const newsize = bytesize.toFixed(2);
    return newsize + ' ' + units[i];
  }

  download (name?: string) {
    const file = this.files.find(item => item.name === name);
    if (file && file.url) {
      const a = document.createElement('a');
      a.href = handleUrl(file.url);
      a.click();
    }
  }

  removeFile (name?: string) {
    const index = this.files.findIndex(item => item.name === name);
    if (index > -1) {
      this.files.splice(index, 1);
    }
  }

  handleSelectionChange (val: any) {
    this.selectList = val.filter((item: any) => item.status !== 1);
  }

  getRowKey (row: any) {
    return row._id;
  }

  checkNewJpgUrl (url: string) {
    if (url.includes('?')) {
      const querys = url.split('?')[1].split('&').map(item => {
        const query = item.split('=');
        return {
          key: query[0],
          value: query[1],
        };
      });
      if (querys.find(item => item.key === 't')) return url;
      return url + '&t=' + Date.now().toString();
    }
    return url + '?t=' + Date.now().toString();
  }

  saveHandle () {
    this.$emit('save', this.image_task_requirement, this.files.filter(item => item.status === 'upload-success'), this.selectList);
  }

  closeHandle () {
    this.$emit('close');
  }
}
