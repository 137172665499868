// @ts-ignore
import { uuid } from './uuid';
import axios from 'axios';

export interface AuthToken {
  host: string;
  key: string;
  policy: string;
  accessId: string;
  signature: string;
  expire: number;
  countdown: number;
  start: number;
}

type Callback = (files: File[], event: Event) => any;

let cb: Callback;
const form = document.createElement('form');
const input = document.createElement('input');

/* istanbul ignore next */
function change (event: Event) {
  if (!input.files || !input.files.length) { return; }
  const files = [...input.files];
  form.reset();
  if (input.disabled) { return; }
  cb && cb(files, event);
}

export function selectFile (options: { [key: string]: any }, callback: Callback) {
  input.accept = '';
  input.disabled = false;
  input.multiple = false;
  cb = callback;
  if (options.disabled) { return; }
  Object.keys(options).forEach((key: string) => {
    (input as any)[key] = options[key];
  });
  input.click();
}

export async function uploadToOss (
  { file, auth, filename }: { file: File | Blob; auth: AuthToken; filename: string },
  options?: { [key: string]: any }
) {
  const key = `${auth.key}${filename}`;
  const form = new FormData();
  form.append('key', key);
  form.append('policy', auth.policy);
  form.append('OSSAccessKeyId', auth.accessId);
  form.append('success_action_status', '200');
  form.append('signature', auth.signature);
  form.append('file', file);
  await axios.post(auth.host, form, options);
  const url = `${auth.host}${key}`;
  return url;
}

export async function uploadFileToOss (
  { file, auth, uid, ext = 'jpg' }: { file: File | Blob; auth: AuthToken; uid?: string; ext?: string },
  options?: { [key: string]: any }
) {
  uid = uid || uuid();
  const filename = `${uid}.${ext}`;
  const url = await uploadToOss({ file, auth, filename }, options);
  return { uid, url };
}

export function base64ToFile (base64: string, filename: string) {
  const arr = base64.split(',');
  const match = arr[0].match(/:(.*?);/);
  const mime = match ? match[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) { u8arr[n] = bstr.charCodeAt(n); }
  return new File([u8arr], filename, { type: mime });
}

export function fileToBase64 (file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}

document.getElementsByTagName('body')[0].appendChild(form);

form.appendChild(input);
form.style.position = 'fixed';
form.style.left = '-10000px';

input.type = 'file';
input.addEventListener('change', change);
