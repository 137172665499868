export class RepeatedAsyncExecutor<T> {
  private readonly asyncFn: (...args: T[]) => Promise<void>;
  private readonly interval: number;
  private timer: number | null | NodeJS.Timeout = null;
  private running = false;
  private args: T[];

  constructor (asyncFn: (...args: T[]) => Promise<void>, interval: number) {
    this.asyncFn = asyncFn;
    this.interval = interval;
    this.args = [];
  }

  private async _execute () {
    if (!this.running) return;

    try {
      await this.asyncFn(...this.args);
    } catch (error) {
      console.error('Error in async function:', error);
    } finally {
      if (this.running) {
        this.timer = setTimeout(() => this._execute(), this.interval);
      }
    }
  }

  public start (...args: T[]) {
    if (this.running) return;
    this.running = true;
    this.args = args;
    this.timer = setTimeout(() => this._execute(), this.interval);
  }

  public stop () {
    this.running = false;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  public restart (...args: T[]) {
    this.stop();
    this.start(...args);
  }
}

// 使用示例
// const asyncFn = async (message: string) => {
//   await new Promise<void>(resolve => setTimeout(resolve, 2000));
//   console.log('Async function executed:', message, new Date().toISOString());
// };
//
// const executor = new RepeatedAsyncExecutor<string>(asyncFn, 5000);
// executor.start('Hello World');

// 如果需要停止执行，可以调用
// executor.stop();

// 如果需要重新开始并更改参数（例如分页操作后），可以调用
// executor.restart('New Message');
